import React from "react"
import HeadlessCTA from "../components/headlessCTA"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Tag from "../components/tag"
import logoDoodad from "../images/logo-doodad.png"
import CTA from "../components/cta"
import BenefitCard from "../components/BenefitCard"
import FeatureCard from "../components/FeatureCard"
import UseCases from "../components/UseCases"
import { IconMessage2, IconSortAscending, IconUsers } from "@tabler/icons"

import forCROs from "../images/use-cases/personas/cro.png"
import forSalesOps from "../images/use-cases/personas/sales-operations.png"
import forStrategy from "../images/use-cases/personas/sales-strategy.png"

import PersonaCard from "../components/PersonaCard"

import HeroImage from "../images/use-cases/revops.png"
import Feature1 from "../images/use-cases/revops 1.png"
import Feature2 from "../images/use-cases/revops 2.png"
import Feature3 from "../images/use-cases/revops 3.png"

const RevenueOperationsLanding = () => {
  return (
    <Layout variant="useCase">
      <SEO
        title="Maximize GTM efficiency with a clean and detailed CRM"
        description="Improve sales and marketing efficiency with cleaner and more detailed data on your customers. Drive better account prioritisation and customised messaging."
      />
      <div className="max-w-screen overflow-x-hidden relative">
        <div className="container mx-auto px-4 sm:px-6 mb-12 xl:mb-64">
          <div className="grid grid-cols-1 xl:grid-cols-2 gap-24 py-24 items-center">
            <div>
              <Tag>Tactic for Revenue and Sales Operations</Tag>
              <h1
                className="font-extrabold text-white text-5xl md:text-7xl mt-12 mb-4"
                style={{
                  mixBlendMode: "overlay",
                  fontFeatureSettings: "'ss02' on, 'ss03' on, 'ss01' on",
                }}
              >
                Maximize GTM efficiency with a clean and detailed CRM
              </h1>

              <h2 className="text-white text-xl mb-8">
                Improve sales and marketing efficiency with cleaner and more
                detailed data on your customers. Drive better account
                prioritisation and customised messaging.
              </h2>

              <HeadlessCTA />
            </div>
            <img
              src={HeroImage}
              className="xl:absolute xl:top-24 xl:left-1/2 shadow-violet-400/50 shadow-2xl rounded-lg"
              style={{ maxHeight: "650px" }}
            />
          </div>
        </div>
      </div>
      <div className="flex gap-4 items-center">
        <div className="h-px flex-1 bg-salmon"></div>
        <div className="rounded-full border border-salmon text-salmon py-1 px-4 leading-none">
          Problems we solve for RevOps &amp; SalesOps &darr;
        </div>
        <div className="h-px flex-1 bg-salmon"></div>
      </div>

      <img
        src={logoDoodad}
        className="absolute mt-36"
        style={{ maxHeight: "650px" }}
      />

      <div className="container p-4 md:p-24 mx-auto flex flex-col lg:flex-row gap-12 text-white my-24 bg-white/10 rounded-xl backdrop-blur-lg border border-white/20">
        <h2 className="font-bold text-4xl md:text-6xl flex-1 leading-tight z-10">
          All of the wrong data. None of the right data.
        </h2>
        <div className="text-xl flex-1 z-10 md:pt-48">
          <p className="mb-4">
            Every day, sales activity and list uploads clog up your CRM with
            dirty data. Some's outdated, some's inaccurate - it's painfully
            chaotic. You are overwhelmed with data vendors, but desperate for
            data analysts.
          </p>
          <p className="text-lilac">
            Clean and enrich your CRM with Tactic. On demand company and people
            research help you add and enrich relevant data, sorted and filtered
            according to your criteria. A seamless integration with Salesforce
            helps you do this in just a few clicks.
          </p>
        </div>
      </div>

      <div className="flex flex-col gap-9 container mx-auto px-4 sm:px-6">
        <div className="flex flex-col gap-4 lg:text-center max-w-2xl mx-auto mb-12">
          <h2 className="font-bold text-4xl md:text-5xl flex-1 leading-tight text-white z-10">
            Clean, relevant data in the format you need.
          </h2>
          <p className="text-medium-grey text-xl">
            Even the best strategists are lost without up-to-date information
            readily available, in the right format. Put yourself on the front
            foot with Tactic.
          </p>
        </div>
        <div className="flex flex-col lg:flex-row gap-2">
          <BenefitCard
            icon={
              <IconSortAscending size={48} stroke={1.5} className="text-blue" />
            }
            heading="Increase conversion rates with better customer targeting"
            body="With a CRM enriched with best fit companies, you know you're not wasting time focusing on the wrong accounts."
          />
          <BenefitCard
            icon={<IconMessage2 size={48} stroke={1.5} className="text-blue" />}
            heading="Identify the best markets and tactical segments"
            body="Increase your TAM in a data driven way. Enhance territory and vertical planning by creating structure from all your data sources at once."
          />
          <BenefitCard
            icon={<IconUsers size={48} stroke={1.5} className="text-blue" />}
            heading="Increase predictability in sales planning"
            body="Our research algorithms give you perfect data for models, plans and budgets - taking risky estimates out of the equation. Grow your total addressable market based on information specific to your GTM."
          />
        </div>
      </div>

      <div className="flex flex-col gap-9 container mx-auto px-4 sm:px-6 my-48">
        <div className="flex flex-col gap-4 lg:text-center max-w-2xl mx-auto">
          <h2 className="font-bold text-5xl flex-1 leading-tight text-white">
            Tactic is for
          </h2>
        </div>
        <div className="flex flex-col lg:flex-row gap-24 mx-auto">
          <PersonaCard
            image={forSalesOps}
            jobTitle="Sales Operations"
            quote1="“We don't have a reliable source of ICP accounts for the sales team.”"
            quote2="“Our reps are spending 80% of their time on 20% of their revenue.”"
          />
          <PersonaCard
            image={forCROs}
            jobTitle="Chief Revenue Officers"
            quote1="“I don't have a clear picture of our TAM, and how to increase it.”"
            quote2="“Pipeline, revenue and hiring projections are just guesses.”"
          />
          <PersonaCard
            image={forStrategy}
            jobTitle="Strategy and Analytics"
            quote1="“I needed specific data points to understand where to find my ICPs in real life.”"
            quote2="“Sales and marketing teams do not trust the data we provide.”"
          />
        </div>
      </div>

      <div className="flex flex-col gap-9 container mx-auto px-4 sm:px-6">
        <h2 className="text-white text-5xl font-bold mb-12 text-center">
          Top features for RevOps and SalesOps
        </h2>
        <div className="flex flex-col gap-9">
          <FeatureCard
            heading="A search engine for everything"
            body="You can finally close all your browser tabs, because Tactic will search for insights across the whole web including annual company reports, T&Cs, 'about us' pages, LinkedIn, Zoominfo, nestled PDFs, job posts and more."
            direction="left"
            image={Feature1}
          />
          <FeatureCard
            heading="Adjustable reports"
            body="You can be agile with your data, curating reports from your Tactic results in just a few clicks to have the relevant people and keyword insights to hand while you're on a sales call or designing your outbound messaging."
            direction="right"
            image={Feature2}
          />
          <FeatureCard
            heading="Set up the next steps"
            body="You can set up Tactic to automatically trigger account prioritisation within Salesforce, email campaigns and updates to your call list based on any updates to your insights when you re-run your project."
            direction="left"
            image={Feature3}
          />
        </div>
      </div>
      <UseCases hideSection="revops" />
      <CTA />
    </Layout>
  )
}

export default RevenueOperationsLanding
